@charset 'utf8';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  font-size: 62.5%;

  background: radial-gradient(
      100% 100% at 100% 0%,
      rgba(255, 106, 106, 0.5) 0%,
      rgba(250, 71, 116, 0.325) 33.33%,
      rgba(219, 31, 125, 0.175) 70.83%,
      rgba(174, 10, 133, 0) 100%
    ),
    url(./trans-black-40.png), radial-gradient(49.14% 87.36% at 50% 50%, #1b2e32 0%, #15262b 66.67%, #0f1d24 100%);
  background-blend-mode: screen, luminosity, normal;
}

body * {
  &,
  &:before,
  &:after {
    margin: 0;
    padding: 0;
    font: inherit;
    box-sizing: border-box;
    line-height: 1;
  }
}

main {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}

h1 {
  padding: 8vw 0 4vw;
  position: relative;
  z-index: 1;
}

p {
  position: relative;
  z-index: 0;
  font-size: 4vw;
  height: 4vw;
  letter-spacing: 0.25em;
  font-family: 'Poppins', sans-serif;
  color: rgba(#fff, 0.8);
}

body.loading {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.4) 33%,
      rgba(0, 0, 0, 0.2) 67%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(80.01deg, #1fdc59 0%, #77fa49 100%);

  h1,
  p {
    display: none;
  }
}

body.appear {
  perspective: 800px;
  perspective-origin: center center;

  background: radial-gradient(
      100% 100% at 100% 0%,
      rgba(255, 106, 106, 0.5) 0%,
      rgba(250, 71, 116, 0.325) 33.33%,
      rgba(219, 31, 125, 0.175) 70.83%,
      rgba(174, 10, 133, 0) 100%
    ),
    url(./trans-black-40.png), radial-gradient(49.14% 87.36% at 50% 50%, #1b2e32 0%, #15262b 66.67%, #0f1d24 100%);
  background-blend-mode: screen, luminosity, normal;

  h1,
  p {
    display: block;
    transform-origin: center center;
  }

  h1 {
    animation: logoAppear 400ms ease-in;
    animation-fill-mode: both;
  }

  p {
    animation: taglineAppear 400ms ease-out 1200ms;
    animation-fill-mode: both;
  }
}

@keyframes logoAppear {
  0% {
    transform: translate3D(0, -50px, 1000px) rotateZ(10deg);
    filter: brightness(0.4) blur(5vw) drop-shadow(0 25vw 15vw black);
  }

  90% {
    transform: translate3D(0, -50px * 0.1, 100px) rotateZ(-3deg);
  }

  100% {
    transform: translate3D(0, 0, 0) rotateZ(0deg);
    filter: brightness(1) blur(0) drop-shadow(0 0.1vw 0.1vw black);
  }
}

@keyframes taglineAppear {
  0% {
    transform: translate3D(0, -1vw, -10px);
    filter: blur(0.2vw);
    color: #ff6a6a00;
  }
  20% {
    color: #ff6a6a40;
  }

  40% {
    color: #fdc7b480;
  }

  60% {
    color: #ebfdb4c0;
  }

  100% {
    transform: translate3D(0, 0, 0);
    filter: blur(0);
    color: #ffffffe0;
  }
}
