html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  background-blend-mode: screen, luminosity, normal;
  background: radial-gradient(100% 100% at 100% 0, #ff6a6a80 0%, #fa477453 33.33%, #db1f7d2d 70.83%, #ae0a8500 100%), url("trans-black-40.a26dcc51.png"), radial-gradient(49.14% 87.36%, #1b2e32 0%, #15262b 66.67%, #0f1d24 100%);
  font-size: 62.5%;
}

body *, body :before, body :after {
  font: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1;
}

main {
  height: 100vh;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

h1 {
  z-index: 1;
  padding: 8vw 0 4vw;
  position: relative;
}

p {
  z-index: 0;
  height: 4vw;
  letter-spacing: .25em;
  color: #fffc;
  font-family: Poppins, sans-serif;
  font-size: 4vw;
  position: relative;
}

body.loading {
  background: linear-gradient(#000c 0%, #0006 33%, #0003 67%, #0000 100%), linear-gradient(80.01deg, #1fdc59 0%, #77fa49 100%);
}

body.loading h1, body.loading p {
  display: none;
}

body.appear {
  perspective: 800px;
  perspective-origin: center;
  background-blend-mode: screen, luminosity, normal;
  background: radial-gradient(100% 100% at 100% 0, #ff6a6a80 0%, #fa477453 33.33%, #db1f7d2d 70.83%, #ae0a8500 100%), url("trans-black-40.a26dcc51.png"), radial-gradient(49.14% 87.36%, #1b2e32 0%, #15262b 66.67%, #0f1d24 100%);
}

body.appear h1, body.appear p {
  transform-origin: center;
  display: block;
}

body.appear h1 {
  animation: logoAppear .4s ease-in both;
}

body.appear p {
  animation: taglineAppear .4s ease-out 1.2s both;
}

@keyframes logoAppear {
  0% {
    filter: brightness(.4) blur(5vw) drop-shadow(0 25vw 15vw #000);
    transform: translate3d(0, -50px, 1000px)rotateZ(10deg);
  }

  90% {
    transform: translate3d(0, -5px, 100px)rotateZ(-3deg);
  }

  100% {
    filter: brightness() blur() drop-shadow(0 .1vw .1vw #000);
    transform: translate3d(0, 0, 0)rotateZ(0deg);
  }
}

@keyframes taglineAppear {
  0% {
    filter: blur(.2vw);
    color: #ff6a6a00;
    transform: translate3d(0, -1vw, -10px);
  }

  20% {
    color: #ff6a6a40;
  }

  40% {
    color: #fdc7b480;
  }

  60% {
    color: #ebfdb4c0;
  }

  100% {
    filter: blur();
    color: #ffffffe0;
    transform: translate3d(0, 0, 0);
  }
}

/*# sourceMappingURL=index.ad56cc3c.css.map */
